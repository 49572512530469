import React from 'react';
import { Route, Switch } from 'react-router';
import Home from './components/home/Home';
import Layout from './components/Layout';
import NoMatchPage from './components/NoMatchPage';

const App = (): React.ReactElement => {
    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route component={NoMatchPage} />
            </Switch>
        </Layout>
    );
};

export default App;

import React from 'react';

const NoMatchPage = (): React.ReactElement => {
    return (
        <h1 style={{ textAlign: 'center', fontSize: '400%' }}>
            <b>404</b>
            <br />
            Not found
        </h1>
    );
};

export default NoMatchPage;

import React, { useEffect, useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';
import Logo from './Logo';

const Brand = styled.div`
    display: flex;
    height: 2em;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

const Title = styled.span`
    font-weight: 500;
    margin-right: 2em;
    margin-left: 1em;
    font-size: 24px;
`;

const Header = styled.header`
    margin-bottom: 2em;
`;

const NavMenu = (): React.ReactElement => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Just a placeholder. Replaces componentDidMount and componentDidUpdate
    });

    const toggle = (): void => {
        setIsOpen(!isOpen);
    };

    // TODO: Hide and show menu items based on user roles (see App.js)
    return (
        <Header>
            <Navbar style={{ paddingBottom: '0', color: 'red' }} className="navbar-expand-sm navbar-toggleable-sm box-shadow mb-0" light>
                <Container fluid>
                    <NavbarBrand tag={RRNavLink} to="/">
                        <Brand>
                            <Logo />
                            <Title>Security Tools</Title>
                        </Brand>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} className="mr-2" />
                </Container>
            </Navbar>
            <Navbar style={{ paddingTop: '0', color: 'red' }} className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-0" light>
                <Collapse className="d-sm-inline-flex flex-sm-row" isOpen={isOpen} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={RRNavLink} to="/" exact activeClassName="active">
                                Hjem
                            </NavLink>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
        </Header>
    );
};

export default NavMenu;

import React, { ReactElement, useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, CardColumns, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import tools from '../../data/tools.json';
import SecurityTool from '../../models/SecurityTool';

const Home: React.FC = () => {
    const [uniqueTags, setUniqueTags] = useState([]);
    const [filter, setFilter] = useState([]);
    const [filteredTools, setFilteredTools] = useState([]);

    useEffect(() => {
        const allTags = tools.flatMap((t) => t.tags);
        const unique = allTags.filter((item, i, ar) => ar.indexOf(item) === i).sort((a, b) => a.localeCompare(b, 'nb', { sensitivity: 'accent' }));
        setUniqueTags(unique);
    }, [tools, setUniqueTags]);

    useEffect(() => {
        const filtered = tools
            .filter((tool: SecurityTool) => !tool.hide && (filter.length === 0 || tool.tags.filter((t) => filter.includes(t)).length >= filter.length))
            .sort((a, b) => a.name.localeCompare(b.name, 'nb', { sensitivity: 'accent' }));

        setFilteredTools(filtered);
    }, [tools, filter, setFilteredTools]);

    const toggleFilter = (tag: string): void => {
        if (filter.includes(tag)) {
            setFilter([...filter.filter((t) => t !== tag)]);
        } else {
            setFilter([...filter, tag]);
        }
    };

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <strong>Filter: </strong>
                {uniqueTags.map((tag) => (
                    <Badge
                        key={tag}
                        style={{ marginRight: 4, cursor: 'pointer' }}
                        color={filter.includes(tag) ? 'primary' : 'secondary'}
                        onClick={() => toggleFilter(tag)}>
                        {filter.includes(tag) && 'X'} {tag}
                    </Badge>
                ))}
            </div>
            <CardColumns>
                {filteredTools.map((tool) => (
                    <SecTool key={tool.name} tool={tool} />
                ))}
            </CardColumns>
        </div>
    );
};

const SecTool = ({ tool }: { tool: SecurityTool }): ReactElement => {
    return (
        <Card>
            <CardBody>
                <CardTitle tag="h5">
                    {tool.name}
                    {tool?.freeToUse && (
                        <Badge color="success" style={{ float: 'right' }}>
                            GRATIS
                        </Badge>
                    )}
                </CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">
                    {tool.tags
                        .sort((a, b) => a.localeCompare(b, 'nb', { sensitivity: 'accent' }))
                        .map((tag) => (
                            <Badge key={tag} style={{ marginRight: 4 }}>
                                {tag}
                            </Badge>
                        ))}
                </CardSubtitle>
                <CardText>{tool.description}</CardText>
                {tool.urls?.map((toolUrl) => (
                    <Button href={toolUrl.url} rel="nofollow" key={toolUrl.url}>
                        {toolUrl.text}
                    </Button>
                ))}
            </CardBody>
        </Card>
    );
};

export default Home;

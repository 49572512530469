import React, { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import '../App.scss';
import Footer from './Footer';
import NavMenu from './NavMenu';

const Layout = (props: { children: React.ReactNode }): ReactElement => {
    return (
        <BrowserRouter>
            <div className="main-container">
                <NavMenu />
                <Container className="content" fluid>
                    {props.children}
                </Container>
                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default Layout;

import React from 'react';
import { Container } from 'reactstrap';

const Footer = (): React.ReactElement => {
    return (
        <footer className="footer">
            <Container fluid>
                <div className="d-flex justify-content-center">
                    <p>
                        Bouvet Security Tools List © Bouvet ASA <br />
                        We do not use cookies, tracking, or other nasty things here
                    </p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
